<template>
  <div class="container">
      <Title msg="Ranking"/>
      <Ranking />
  </div>
</template>
<script>
import Ranking from '@/components/student/ranking/index.vue'
import Title from '@/views/student/constants/title.vue'

export default {
  name: 'viewRanking',
  components: {
    Ranking,
    Title
  },
  data () {
    return {
    }
  },
  created () {
  },
  computed: {
  }
}
</script>

<style scope>

</style>