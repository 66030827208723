<template>
  <div>
    <!---  End Preload -->
    <div v-if="loading">
      <loadingQuiz />
    </div>

    <!-- start friend -->
    <div class="row justify-content-md-center" v-else v-for="rank in ranking" :key="rank.id">
      <div class="col-12 col-sm-12 col-md-6 col-lg-4 dcourse" >
        <div class="row">
          <div class="imgav">
            <div class="cAvatar">
              <img
                :src="appDomainImg + 'profile/15.' + rank.student.user.avatar"
                class="cImage"
              />
            </div>
          </div>
          <div class="col rankin_detall">
            <p> <strong>{{ rank.student.user.name }}</strong> </p>
            <p>{{ rank.student.block.name }}</p>
          </div>
          <div class="col- content_score">
              <p> {{ rank.amount }}</p>
          </div>
        </div>
    </div>
  </div>
  </div>
</template>

<script>
import axios from 'axios';
import loadingQuiz from "@/views/student/constants/loading/friends.vue";

export default {
  components: {
        loadingQuiz
    },
  data() {
    return {
      loading: true,
      ranking: []
    };
  },
  created() {
    this.getRanking();
  },
  methods: {
   getRanking(){
        let self = this;
        this.loading = true
        const url = this.appDomainApi1 + "ranking"
        axios
            .get(url, { headers: { Authorization: "Bearer " + this.$store.state.token } })
            .then(function(response) {
                if (response.data.code == 200) {
                    self.ranking = response.data.ranking
                    self.loading = false
                } else {
                  console.log("error");
                }

            })
    },
  },
};
</script>

<style>
.dcourse {
    border-radius: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 10px;
    margin-left: 0.1px;
    margin-right: 0.1px;
    margin-bottom: 10px;
    background: white;

}
.rankin_detall>p {
    margin-top: 97px;
    margin-left: 6px;
    padding: 0;
    margin-top: 5px;
    font-size: 16px;
    color: #1d2b8d;
    line-height: 1.2;
}
.imgav {
  display: flex;
  align-content: center;
  justify-content: center;
}
.cAvatar {
  position: relative;
  width: 75px;
  height: 75px;
  background-color: white;
  border-radius: 50%;
  overflow: hidden;
}
.cImage {
  position: absolute;
  width: 75px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
</style>
